import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/images/bway-headshots-logo.png";
import handlePrintPDF from "../../util/handlePrintPDF";
import InputTitle from "../common/inputTitle";
import RadioButton from "../common/radioButton";
import ConfirmModal from "../common/confirmModel";
import { DesignerProducts } from "../../services/constants";
import { getDesignerProductName } from "../../services/utility";
import { IAppState } from "../../types/states";
import { PaperTypeOptions, PromotionalUseOptions } from "../../types/designer";

export type ReviewProps = {
  state: IAppState;
  setState: React.Dispatch<React.SetStateAction<IAppState>>;
  initialState: IAppState;
};
const Review = ({ state, setState, initialState }: ReviewProps) => {
  const {
    imageBase64File,
    isPortrait,
    selectedBorderOption,
    selectedBorderTypeOption,
    selectedOutline,
    selectedOutlineWidth,
    colorPrintOption,
    imagePositionForPDF,
    imageScalingForPDF,
    textEditorValue,
    PDFURL,
    productSelection,
    selectedPaperTypeOption,
    selectedPromotionalUseOption,
  } = state;

  const navigate = useNavigate();

  const [showConfirm1, setShowConfirm1] = useState(false);
  const [showConfirm2, setShowConfirm2] = useState(false);

  const handleConfirmation1 = (confirmed: boolean) => {
    if (confirmed) {
      if (!imageBase64File) {
        navigate("/");
        return;
      }
      const appStates = Object.assign({}, state);
      delete appStates.imageBase64File;
      delete appStates.imageForCanvas;
      delete appStates.finalPDFBlob;
      sessionStorage.setItem("application-states", JSON.stringify(appStates));
      navigate("/checkout");
    }
    setShowConfirm1(false);
  };

  const handleConfirmation2 = (confirmed: boolean) => {
    if (confirmed) {
      setState(initialState);
      navigate("/");
    }
    setShowConfirm2(false);
  };

  const handlePaperTypeOptionChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setState((prevState) => ({
      ...prevState,
      selectedPaperTypeOption: e.target.value as PaperTypeOptions,
    }));
  };

  const handlePromotionalUseOptionChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setState((prevState) => ({
      ...prevState,
      selectedPromotionalUseOption: e.target.value as PromotionalUseOptions,
    }));
  };

  const paperTypeOption = [
    {
      id: "gloss-type",
      name: "paper-type",
      value: "Gloss",
      checked: selectedPaperTypeOption === "Gloss",
      onChange: handlePaperTypeOptionChange,
      label: "Gloss",
    },
    {
      id: "matte-border",
      name: "paper-type",
      value: "Matte",
      checked: selectedPaperTypeOption === "Matte",
      onChange: handlePaperTypeOptionChange,
      label: "Matte / Silk",
    },
  ];

  const promotionalUseOption = [
    {
      id: "promotion-allowed",
      name: "promotional-use",
      value: "Allowed",
      checked: selectedPromotionalUseOption === "Allowed",
      onChange: handlePromotionalUseOptionChange,
      label: "This headshot may be utilized for promotional purposes.",
    },
    {
      id: "promotion-denied",
      name: "promotional-use",
      value: "Denied",
      checked: selectedPromotionalUseOption === "Denied",
      onChange: handlePromotionalUseOptionChange,
      label:
        "I do not grant permission for my headshot to be utilized for promotional purposes.",
    },
  ];

  useEffect(() => {
    if (!imageBase64File) {
      sessionStorage.removeItem("application-states");
      sessionStorage.removeItem("checkout-states");
      sessionStorage.removeItem("show-shipping-rates");
      navigate("/", { replace: true });
    }
  }, [imageBase64File, navigate]);

  return (
    <>
      {showConfirm1 && (
        <ConfirmModal
          handleConfirmation={handleConfirmation1}
          data={{
            title: "Confirmation",
            message:
              "I have carefully reviewed and approve this job, including spelling and design elements. I understand that there is a difference between the image on a digital screen and a printed image, and that color matching may differ with various printing services. The print shop will not be held responsible for design, usage, image resolution, color matching, or spelling errors.",
            cancelBox: true,
            trueMessage: "I Agree",
            falseMessage: "Cancel",
          }}
        />
      )}

      {showConfirm2 && (
        <ConfirmModal
          handleConfirmation={handleConfirmation2}
          data={{
            title: "Discard progress and go to home page.",
            message: "Do you want to discard the current progress?",
            cancelBox: true,
            trueMessage: "Yes, Discard",
            falseMessage: "No",
          }}
        />
      )}
      <div className="flex h-full min-h-screen">
        <div className="w-1/5 min-w-[315px] flex flex-col justify-between p-5 bg-white min-h-screen h-full">
          <div>
            {/* Logo image */}
            <div className="w-full text-center mb-2">
              <img
                src={Logo}
                alt="BWAY HEADSHOTS"
                onClick={() => setShowConfirm2(true)}
                className="w-[141px] mx-auto"
              />
            </div>
            <label className="block text-xl text-black-200 font-semibold mb-2">
              Review - {getDesignerProductName(productSelection)}
            </label>
            <hr />
            <br />
            <div>
              <label className="block text-sm text-black-200 font-medium">
                Orientation:
              </label>
              <label className="block text-md text-black-200 font-semibold mb-5">
                {isPortrait ? "Portrait" : "Landscape"}
              </label>
            </div>
            {productSelection === DesignerProducts.RESUME_8X10 && (
              <>
                <div>
                  <label className="block text-sm text-black-200 font-medium">
                    Color Print:
                  </label>
                  <label className="block text-md text-black-200 font-semibold mb-5">
                    {colorPrintOption ? "Yes" : "No - B&W"}
                  </label>
                </div>
                <hr />
                <div className="my-3 max-w-[315px]">
                  <label className="block text-gray-500 italic text-sm">
                    Resume text may appear in low quality in the preview. The
                    final prints will be high quality. Download the proof PDF to
                    see the final quality before approving.
                  </label>
                </div>
              </>
            )}
            {(productSelection === DesignerProducts.HEADSHOT_PRINTS ||
              productSelection === DesignerProducts.DIGITAL_HEADSHOT) && (
              <div>
                <label className="block text-sm text-black-200 font-medium">
                  Border Option:
                </label>
                <label className="block text-md text-black-200 font-semibold mb-5">
                  {selectedBorderOption === "blackBorder"
                    ? "Black Border"
                    : selectedBorderOption === "whiteBorder"
                      ? "White Border"
                      : "No Border"}
                </label>
              </div>
            )}
            {!(selectedBorderOption === "noBorder") && (
              <>
                <div>
                  <label className="block text-sm text-black-200 font-medium">
                    Border Type:
                  </label>
                  <label className="block text-md text-black-200 font-semibold mb-5">
                    {selectedBorderTypeOption === "wide" ? "Wide" : "Standard"}
                  </label>
                </div>
                <div>
                  <label className="block text-sm text-black-200 font-medium">
                    Outline:
                  </label>
                  <label className="block text-md text-black-200 font-semibold mb-5">
                    {selectedOutline === "outlineYes"
                      ? "Yes - " + selectedOutlineWidth + "pt"
                      : "No"}
                  </label>
                </div>
              </>
            )}
            {(productSelection === DesignerProducts.HEADSHOT_PRINTS ||
              productSelection === DesignerProducts.YOUR_DESIGN) && (
              <>
                <hr />
                <br />
                <div className="flex flex-col space-y-8">
                  <div>
                    <InputTitle title={"Select Paper Type"} />
                    <div className="flex items-start gap-4">
                      {paperTypeOption.map((item, index) => (
                        <RadioButton
                          key={index}
                          id={item.id}
                          name={item.name}
                          value={item.value}
                          checked={item.checked}
                          onChange={item.onChange}
                          label={item.label}
                        />
                      ))}
                    </div>
                  </div>
                  <div>
                    <InputTitle title={"Promotional Use"} />
                    <div className="flex flex-col items-start space-y-4 text-wrap">
                      {promotionalUseOption.map((item, index) => (
                        <RadioButton
                          key={index}
                          id={item.id}
                          name={item.name}
                          value={item.value}
                          checked={item.checked}
                          onChange={item.onChange}
                          label={item.label}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>

          <div className="mt-auto flex items-center gap-4">
            <button
              id="cancel"
              onClick={() => {
                if (!productSelection) navigate("/");
                navigate(`/designer/${productSelection}`);
                setState((prevState) => ({
                  ...prevState,
                  PDFURL: undefined,
                }));
              }}
              className="flex items-center justify-center h-[40px] min-w-[110px] text-[13px] text-black-200 font-medium px-2 border border-black-200 rounded transition ease-in hover:border hover:border-black-200/[.1] hover:bg-beige-100 hover:text-black-200"
            >
              Back
            </button>

            <button
              id="checkOut"
              onClick={() => setShowConfirm1(true)}
              className="flex items-center justify-center h-[40px] text-[13px] text-white font-medium px-12 bg-black-200 border border-black-200 rounded transition ease-in hover:border hover:border-black-200/[.1] hover:bg-beige-100 hover:text-black-200 disabled:bg-black-200/[.1] disabled:text-black-200/[.5] disabled:border-black-200/[.1]"
            >
              Next
            </button>
          </div>
        </div>
        <div className="w-4/5 overflow-y-auto overflow-x-hidden max-h-[calc(100vh_0px)]">
          <div className="bg-beige-400 flex flex-col justify-center items-center w-full min-h-screen p-5">
            <div className="relative items-start">
              <div
                className={`absolute -rotate-90 origin-left bottom-[29px] -left-5 flex justify-between items-center mb-2 w-[${
                  isPortrait ? "720px" : "576px"
                }]`}
              >
                <div className="bg-black-200 h-[2px] w-full"></div>
                <div className="w-[200px] text-center text-md font-serif">
                  {isPortrait ? "10 Inches" : "8 Inches"}
                </div>
                <div className="bg-black-200 h-[2px] w-full"></div>
              </div>
              <div>
                <div>
                  <div className="flex w-full justify-between items-center mb-2">
                    <div className="bg-black-200 h-[2px] w-full"></div>
                    <div className="w-[200px] text-center text-md font-serif">
                      {isPortrait ? "8 Inches" : "10 Inches"}
                    </div>
                    <div className="bg-black-200 h-[2px] w-full"></div>
                  </div>
                  <div
                    className={`bg-gray-300 flex justify-center items-center overflow-hidden pointer-events-none relative ${
                      isPortrait ? "w-[576px] h-[720px]" : "w-[720px] h-[576px]"
                    }`}
                  >
                    {PDFURL ? (
                      <img
                        className={`absolute top-[-9px] left-[-9px] bottom-0 right-0 pointer-events-none max-w-none ${
                          isPortrait
                            ? "w-[594px] h-[738px]"
                            : "w-[738px] h-[594px]"
                        }`}
                        src={PDFURL}
                        alt="Review Headshot"
                      />
                    ) : (
                      <div className="loader"></div>
                    )}
                  </div>
                </div>
                <button
                  id="download-proof"
                  onClick={() =>
                    handlePrintPDF(
                      isPortrait,
                      imageScalingForPDF,
                      imagePositionForPDF,
                      selectedBorderOption,
                      selectedBorderTypeOption,
                      selectedOutline,
                      selectedOutlineWidth,
                      textEditorValue,
                      setState,
                      "proof",
                      state.isSelectedFilePdf,
                      imageBase64File,
                      state.canvasRect,
                      state.quillRect,
                    )
                  }
                  className="flex mt-2 items-center mx-auto justify-center h-[40px] text-[13px] text-white font-medium px-12 bg-black-200 border border-black-200 rounded transition ease-in hover:border hover:border-black-200/[.1] hover:bg-gray-700 disabled:bg-black-200/[.1] disabled:text-black-200/[.5] disabled:border-black-200/[.1]"
                >
                  Download Proof PDF
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Review;
